/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { AuthViewModel, Committee, Role, User } from 'entities';
import jwtDecode from 'jwt-decode';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Api from '../../api';
import usePersistedState from '../../hooks/usePersistedState';
import useWebSocket from '../../hooks/useWebSocket';
import { useAlert } from '../alert';

type AuthContextProps = {
  user?: User;
  role: string;
  token?: string;
  roles: string[];
  profileName: string;
  refreshToken?: string;
  tokenIsValid: boolean;
  committees?: Committee[];
  setSession: (session?: AuthViewModel) => void;
  typeInCommittee: (typeId?: string) => boolean;
  userInCommittee: (committeeId?: string) => boolean;
};
const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const Auth: React.FC = ({ children }) => {
  const [token, setToken] = usePersistedState<string | undefined>(
    'token',
    undefined
  );
  const [refreshToken, setRefreshToken] = usePersistedState<string | undefined>(
    'refresh_token',
    undefined
  );
  const [user, setUser] = usePersistedState<User | undefined>(
    'auth_user',
    undefined,
    true
  );

  const confirm = useAlert();
  const queryClient = useQueryClient();
  const [roles, setRoles] = useState<string[]>([]);
  const [profileName, setProfileName] = useState<string>('');

  const setSession = useCallback(
    ({ token, user, refreshToken }: AuthViewModel = {}) => {
      setRefreshToken(refreshToken);
      setToken(token);
      setUser(user);
    },
    [setRefreshToken, setToken, setUser]
  );

  const tokenIsValid = token
    ? (jwtDecode(token) as any).exp > Date.now() / 1000
    : false;

  const role = token && (jwtDecode(token) as any).role;

  const { data: committees } = useQuery(
    'committee',
    () => {
      if (tokenIsValid && role !== 'anonymous')
        return Api.Committee.getByUserId(user?.id);
      return [];
    },
    {
      staleTime: 1000 * 60,
    }
  );

  const userInCommittee = (committeeId?: string) =>
    !!committees &&
    committees?.some((committee) => committee.id === committeeId);

  const typeInCommittee = (typeId?: string) =>
    !!typeId &&
    !!committees &&
    committees.some((committee) => committee.classifications.includes(typeId));

  useEffect(() => {
    const routesToIgnore = ['/', '/login'];

    const destineRoute =
      window.location.pathname === '/login'
        ? undefined
        : window.location.pathname !== '/tracking'
        ? '/login'
        : '/';

    Api.Defaults.setOnError(() => {
      if (!tokenIsValid && routesToIgnore.includes(window.location.pathname))
        return;

      Api.Auth.logout().finally(() => {
        setSession();
        confirm.show({
          title: 'Sessão expirada',
          message: 'Sua sessão expirou, por favor faça login novamente.',
          cancelable: false,
          options: [
            {
              label: 'Ok',
              onClick: () => {
                if (destineRoute) window.location.href = destineRoute;
              },
            },
          ],
        });
      });
    });

    Api.Defaults.setOnRefreshToken((data) => setSession(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSession, tokenIsValid]);

  useEffect(() => {
    if (
      !tokenIsValid &&
      token &&
      !['/', '/login', '/tracking'].includes(window.location.pathname)
    ) {
      Api.Defaults.refreshToken();
    }
  }, [tokenIsValid, token]);

  useEffect(() => {
    if (!tokenIsValid || role === 'anonymous')
      queryClient.invalidateQueries('committee');
  }, [queryClient, role, tokenIsValid]);

  useEffect(() => {
    if (tokenIsValid && user?.id && role !== 'anonymous')
      Api.User.getById(user?.id).then((user) => {
        if (user?.profileSlug !== role) {
          Api.Defaults.refreshToken();
        }
      });
  }, [role, token, tokenIsValid, user?.id]);

  useEffect(() => {
    if (tokenIsValid && role !== 'anonymous')
      Api.Profile.getBySlug(role).then((profile) => {
        setProfileName(profile.name);
        setRoles(profile?.roles?.map((r) => r.slug) || []);
      });
  }, [role, tokenIsValid, token]);

  useWebSocket<Role[]>({
    url: `profile/${role}`,
    onMessage: (roles) => setRoles(roles.map((r) => r.slug)),
  });

  useWebSocket<string>({
    url: `profile/${role}/change`,
    onMessage: () => Api.Defaults.refreshToken(),
  });

  useWebSocket<{ id: string; users: string[] }>({
    url: `committee`,
    onMessage: (committeeChange) => {
      if (
        committees?.some((c) => c.id === committeeChange.id) ||
        (user?.id && committeeChange.users.includes(user.id))
      )
        queryClient.invalidateQueries('committee');
    },
  });

  useWebSocket<{ id: string }>({
    url: `committee/delete`,
    onMessage: (committeeChange) => {
      if (committees?.some((c) => c.id === committeeChange.id))
        queryClient.invalidateQueries('committee');
    },
  });

  useWebSocket({
    url: `user/${user?.id}/disable`,
    onMessage: () => {
      setSession();
      confirm.show({
        title: 'Sua conta foi desativada',
        message: 'Por favor, entre em contato com o administrador do sistema',
        cancelable: false,
        options: [
          {
            label: 'Ok',
            onClick: () => {
              window.location.href = '/login';
            },
          },
        ],
      });
    },
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        role,
        token,
        roles,
        committees,
        setSession,
        profileName,
        refreshToken,
        tokenIsValid,
        userInCommittee,
        typeInCommittee,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth: () => AuthContextProps = () => useContext(AuthContext);

export default Auth;
