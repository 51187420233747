import styled from 'styled-components';

export const Container = styled.div`
  width: min-content;
  min-width: 300px;
  height: min-content;
  outline: none;
  max-height: calc(100vh - 250px);
  margin: 150px auto 0 auto;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  overflow-y: auto;
  padding: 15px 30px;
  display: flex;
  position: relative;
  flex-direction: column;

  p {
    color: ${({ theme }) => theme.colors.text};
  }

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
  }
  #close {
    position: absolute;
    right: 15px;
  }

  #close:not(:hover) {
    background-color: ${({ theme }) => theme.colors.accent} !important;
  }

  #actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  & > div {
    display: flex;
    align-items: baseline;
    button {
      margin-left: 10px;
    }

    button:last-of-type {
      margin-right: -10px;
    }

    .MuiTextField-root,
    #select-option {
      min-width: 200px;
      margin-left: 20px;
    }
  }
`;
