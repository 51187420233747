export default {
  title: 'light',
  colors: {
    accent: '#4196ee',
    primary: '#293778',
    success: '#99D063',
    warning: '#E8C01E',
    error: '#D52F1E',
    bad: '#600A3E',
    light: '#ffffff',
    text: '#888888',
    contrast: '#404040',
    soft: '#acacac',
    background: '#f1f1f1',
    backgroundDarkness: '#f8f8f8',
    overlay: 'rgba(0, 0, 0, 0.38)',
    'form-text': '#707070',
  },
  shadows: ['0px 3px 6px #00000029'],
};
