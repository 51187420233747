/* eslint-disable import/no-unresolved */
import { PersonalizationViewModel } from 'viewModels';
import axios from '../axios';

export const insert = (item: any) => {
  const formData = new FormData();
  formData.append('attachments', item.attachments);
  //eslint-disable-next-line
  new Promise<any>((resolve, reject) => {
    axios
      .post('personalization/insert-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const getAll = () =>
  new Promise<PersonalizationViewModel[]>((resolve, reject) => {
    axios
      .get(`/personalization/get-all`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
