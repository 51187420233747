import { User, UserInputModel, UserProfileInputModel } from 'entities';
import { UserCreateStatus, UserUpdateStatus } from '../../types/enums';
import axios from '../axios';

export const create = (user: UserInputModel) =>
  new Promise<{ id?: string; status: UserCreateStatus }>((resolve, reject) => {
    axios
      .post('/user', user)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const resetPassword = (
  id: string,
  passwordText: string,
  password: string
) =>
  new Promise<{ id?: string; status: number }>((resolve, reject) => {
    axios
      .put('/user/password/reset', {
        id,
        passwordText,
        password,
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const get = (search?: string, page: number = 1) =>
  new Promise<User[]>((resolve, reject) => {
    axios
      .get('/user/list', {
        params: {
          search,
          page,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getItems = (search?: string) =>
  new Promise<{ id: string; value: string }[]>((resolve, reject) => {
    axios
      .get('/user/items', {
        params: {
          search,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getById = (id: string) =>
  new Promise<User>((resolve, reject) => {
    axios
      .get(`/user/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const disable = (id: string) =>
  new Promise<User>((resolve, reject) => {
    axios
      .put(`/user/disable/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const update = (user: UserInputModel) =>
  new Promise<{ id?: string; status: UserUpdateStatus }>((resolve, reject) => {
    axios
      .put('/user', user)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const updateProfile = (user: UserProfileInputModel) =>
  new Promise((resolve, reject) => {
    axios
      .put('/user/profile', user)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
