import { Config, ConfigViewModel } from 'entities';
import axios from '../axios';

export const get = () =>
  new Promise<ConfigViewModel[]>((resolve, reject) => {
    axios
      .get('/config')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getPublic = () =>
  new Promise<ConfigViewModel[]>((resolve, reject) => {
    axios
      .get('/config/public')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const update = (configs: Config[]) =>
  new Promise<ConfigViewModel[]>((resolve, reject) => {
    axios
      .put('/config', configs)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
