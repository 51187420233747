import React from 'react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from 'styled-components';

interface IconButtonProps
  extends Omit<MuiIconButtonProps, 'children' | 'color'> {
  children: React.ReactNode;
  color?: 'primary' | 'accent' | 'error' | 'inherit';
  hexColor?: string;
  onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  children,
  color,
  hexColor,
  onClick,
  ...rest
}) => {
  const theme = useTheme();

  const CustomIconButton = withStyles({
    root: {
      color:
        color === 'primary'
          ? theme.colors.primary
          : color === 'accent'
          ? theme.colors.accent
          : color === 'error'
          ? theme.colors.error
          : hexColor || 'inherit',
    },
  })(MuiIconButton);

  return (
    <CustomIconButton onClick={onClick} {...rest}>
      {children}
    </CustomIconButton>
  );
};

export default IconButton;
