import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';

interface ButtonProps {
  size?: 'small' | 'medium' | 'large';
  shape?: 'outline' | 'solid';
  color: 'primary' | 'accent' | 'error';
  loading?: boolean;
}

const inverseColor = (color: string) => {
  switch (color) {
    case 'primary':
      return 'accent';
    case 'accent':
      return 'primary';
    case 'error':
      return 'bad';
    default:
      return 'primary';
  }
};

export const Button = styled(ButtonBase)<ButtonProps>`
  background: ${({ theme, shape, color }) =>
    shape === 'outline' ? theme.colors.light : theme.colors[color]} !important;
  color: ${({ theme, shape, color }) =>
    shape === 'outline' ? theme.colors[color] : theme.colors.light} !important;
  height: ${({ size }) => (size === 'small' ? '27px' : '42px')};
  font-size: ${({ size }) => (size === 'small' ? '10px' : '12px')};
  border-radius: 21px !important;
  padding: 0px 30px !important;
  outline: none;
  border: ${({ theme, color }) =>
    `${theme.colors[color]} solid 1px`} !important;
  outline: none;
  display: flex !important;
  align-items: center;
  font-weight: ${({ shape }) => (shape === 'outline' ? '600' : '300')};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${({ theme, color }) => theme.colors[color]} !important;
    filter: ${({ shape }) =>
      shape === 'outline' ? 'none' : 'brightness(0.85)'};
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.light} !important;
    p {
      color: ${({ theme }) => theme.colors.light} !important;
    }
  }
  img {
    margin-right: 10px;
  }
  p {
    padding: 0 !important;
    margin-left: 5px;
    color: ${({ theme, shape, color }) =>
      shape === 'outline'
        ? theme.colors[color]
        : theme.colors.light} !important;
    font-size: ${({ size }) => (size === 'small' ? '9px' : '13px')};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  }
  &:focus {
    outline: 1px solid
      ${({ theme, shape, color }) =>
        shape === 'outline'
          ? theme.colors[color]
          : theme.colors[inverseColor(color)]} !important;
    border-color: ${({ theme, shape, color }) =>
      shape === 'outline'
        ? theme.colors[color]
        : theme.colors[inverseColor(color)]} !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background: ${({ theme, shape, color }) =>
      shape === 'outline' ? 'transparent' : theme.colors[color]};
    color: ${({ theme, shape, color }) =>
      shape === 'outline'
        ? theme.colors[color]
        : theme.colors.light} !important;

    p {
      color: ${({ theme, shape, color }) =>
        shape === 'outline'
          ? theme.colors[color]
          : theme.colors.light} !important;
    }
  }

  p,
  strong,
  span,
  img,
  svg {
    visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
  }

  & {
    color: ${({ loading }) => (loading ? 'transparent' : 'initial')};
  }

  .MuiCircularProgress-indeterminate {
    position: absolute;
    height: unset !important;
    width: unset !important;

    svg {
      visibility: ${({ loading }) => (loading ? 'visible' : 'hidden')};
      margin: 0 !important;
      width: ${({ size }) => (size === 'small' ? '15px' : '25px')} !important;
      height: ${({ size }) => (size === 'small' ? '15px' : '25px')} !important;
      stroke: ${({ theme, shape, color }) =>
        shape === 'outline'
          ? theme.colors[color]
          : theme.colors.light} !important;
    }

    &:hover svg {
      stroke: ${({ theme }) => theme.colors.light} !important;
    }
  }
`;
