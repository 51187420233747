/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Button as ButtonStyle } from './styles';
import { useAlert } from '../../context';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MB } from '../../utils/file';

interface Props {
  ref?: React.Ref<any>;
  id?: string;
  type?: 'file' | string;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'accent' | 'error';
  shape?: 'outline' | 'solid';
  children?: any;
  disabled?: boolean;
  loadable?: boolean;
  onMouseEnter?: (e: React.MouseEvent<any>) => void;
  onMouseLeave?: (e: React.MouseEvent<any>) => void;
  onClick?: (e: React.MouseEvent<any>, stopLoading?: () => void) => void;
  onFilesChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Button: React.FC<Props> = ({
  children,
  ref,
  id,
  size,
  type,
  shape,
  color,
  disabled = false,
  loadable = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFilesChange,
}) => {
  const confirm = useAlert();
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    if (type === 'file') {
      (inputFile?.current as any).click();
    }
    if (onClick) {
      setLoading(true);
      onClick(e, () => setLoading(false));
    }
  };

  return (
    <>
      <input
        type="file"
        id="file"
        multiple
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => {
          if (
            Array.from(e?.target?.files || []).reduce(
              (acc, file) => acc + file.size,
              0
            ) > MAX_FILE_SIZE
          ) {
            e.target.value = '';
            confirm.show({
              title: 'Arquivo muito grande',
              message: `O tamanho total máximo permitido é de ${MAX_FILE_SIZE_MB}MB`,
              options: [
                {
                  label: 'Ok',
                },
              ],
            });
          } else {
            if (onFilesChange) onFilesChange(e);
            e.target.value = '';
          }
        }}
      />
      <ButtonStyle
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
        id={id}
        loading={loadable && loading}
        size={size}
        shape={shape}
        color={color || (shape === 'outline' ? 'accent' : 'primary')}
        disabled={disabled}
        onClick={handleClick}
      >
        <CircularProgress size={size} color="inherit" />
        {children}
      </ButtonStyle>
    </>
  );
};

export default Button;
