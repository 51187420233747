import { createGlobalStyle } from 'styled-components';

interface GlobalStyleProps {
  networkError?: boolean;
}

export default createGlobalStyle<GlobalStyleProps>`

::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;    
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    :focus {
      outline-color: ${({ theme }) => theme.colors.primary};
      outline-style: solid;
      outline-width: 1px;
    }
    
    --accent: #4196ee;
    --primary: #293778;
    --success: #99D063;
    --warning: #E8C01E;
    --error: #D52F1E;
    --bad: '#600A3E',
    --light-ff: #ffffff;
    --light-f1: #f1f1f1;
    --light-e6: #e6e6e6;
    --light-d9: #d9d9d9;
    --light-cc: #cccccc;
    --light-b3: #b3b3b3;
    --light-95: #959595;
    --light-88: #888888;
    --light-87: #878787;
    --light-7b: #7b7b7b;
    --light-70: #707070;
    --contrast: '#404040',
    --soft: '#acacac',
    --bg-10: rgba(0, 0, 0, 0.1);
    --bg-25: rgba(0, 0, 0, 0.25);
    --bg-38: rgba(0, 0, 0, 0.38); /*bg*/
    --bg-50: rgba(0, 0, 0, 0.5);
    --bg-75: rgba(0, 0, 0, 0.75);
    --gradient: transparent linear-gradient(343deg, #309ac9 0%, #201d75 100%) 0%
        0% no-repeat padding-box;
}


 #root { 
    -webkit-filter: ${({ networkError }) =>
      networkError ? 'grayscale(100%)' : 'none'};
    -moz-filter: ${({ networkError }) =>
      networkError ? 'grayscale(100%)' : 'none'};
    filter: ${({ networkError }) =>
      networkError ? 'grayscale(100%)' : 'none'};

    transition: filter 0.3s ease-in-out;
    background-color: ${({ theme }) => theme.colors.background};
}


p, h1, h2, h3, h4, h5, h6, span, a, button{
  white-space: pre-line;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill::first-line,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    font-family: 'Poppins', sans-serif !important;
    -webkit-text-fill-color: #293778 !important;
    -webkit-box-shadow: 0 0 0px 1000px #F2F8FE inset;
    transition: background-color 5000s ease-in-out 0s;
}

::-moz-selection {
  color: ${({ theme }) => theme.colors.light};
  background: ${({ theme }) => theme.colors.accent};
}

::selection {
  color: ${({ theme }) => theme.colors.light};
  background: ${({ theme }) => theme.colors.accent};
}

.info {
  display: -webkit-box !important;
  align-items: center;
  justify-content: center;
  color: #f1f1f1;
  background-color: #acacac !important;
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  margin-left: 15px;
}

.info:after {
  content: 'i';
}

.no-selection {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.MuiTooltip-popper {
  strong {
    font-size: 14px;
  }
  p{
    margin-top: 5px;
  font-size: 13px;
}
}

 @keyframes hide {
    0% {
      opacity: 1;
      transform: scaleY(1);
    }
    99%{
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
      opacity: 0;
      z-index: -1;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
      z-index: 5;
    }
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.rotating.reverse {
  -webkit-animation: rotating 2s linear infinite reverse;
  -moz-animation: rotating 2s linear infinite reverse;
  -ms-animation: rotating 2s linear infinite reverse;
  -o-animation: rotating 2s linear infinite reverse;
  animation: rotating 2s linear infinite reverse;
}

`;
