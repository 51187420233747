import { Role } from 'entities';
import axios from '../axios';

export const getBySlug = (slug: string) =>
  new Promise<Role>((resolve, reject) => {
    const url = `/role/${slug}`;
    axios
      .get(url)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getByProfileSlug = (profileSlug: string) =>
  new Promise<Role[]>((resolve, reject) => {
    const url = `/role/profile/${profileSlug}`;
    axios
      .get(url)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getEnabled = () =>
  new Promise<Role[]>((resolve, reject) => {
    axios
      .get('/role')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
