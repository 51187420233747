import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';

export const CloseButtonStyle = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  border: none;
  border-radius: 50% !important;
  background-color: ${({ theme }) => theme.colors.primary} !important;
  color: ${({ theme }) => theme.colors.light} !important;
  transition: background-color 0.3s ease-in-out;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.error} !important;
    transition: background-color 0.3s ease-in-out;
  }
`;
