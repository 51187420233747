import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { useTheme } from 'styled-components';

type SelectOption = {
  value: number | string;
  text: string;
};

interface SelectProps {
  id?: string;
  label: string;
  placeholder?: string;
  options: SelectOption[];
  size: 'small' | 'medium' | undefined;
  value: string | number;
  required?: boolean;
  error?: boolean;
  onChange?: (
    // eslint-disable-next-line no-unused-vars
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

const Select: React.FC<SelectProps> = ({
  id,
  label,
  placeholder,
  options,
  value,
  size,
  error,
  required,
  onChange,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    input: {
      color: theme.colors.primary,
    },
  }));
  const classes = useStyles();

  return (
    <FormControl id={id} size={size} error={error}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        required={required}
        value={value}
        onChange={onChange}
        inputProps={{
          classes: {
            root: classes.input,
          },
        }}
      >
        <MenuItem value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} selected={option.value === value}>
            {option.text}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
