import { useState } from 'react';

interface SendProps {
  title: string;
  data?: import('props').NotificationOptions;
  onClick?: (event: Event) => void;
  onClose?: (event: Event) => void;
}

const notifications: Notification[] = [];
const clearBrowserNotifications = () => {
  notifications.forEach((notification) => notification.close());
};

const closeBrowserNotification = (tag: string) =>
  notifications.forEach(
    (notification) => notification.tag === tag && notification.close()
  );

const useBrowserNotifications = () => {
  const [notification, setNotification] = useState<Notification>();

  const getNewNotification: (props: SendProps) => Notification = ({
    title,
    data,
    onClick,
    onClose,
  }) => {
    const newNotification = new Notification(title, data);
    if (onClose)
      newNotification.onclose = (e) => {
        notifications.splice(notifications.indexOf(newNotification), 1);
        onClose?.(e);
      };

    newNotification.onclick = (e) => {
      window.focus();
      onClick?.(e);
      newNotification.close();
    };

    /*document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        newNotification.close();
      }
    });*/

    setNotification(newNotification);
    notifications.push(newNotification);
    return newNotification;
  };

  const send: (props: SendProps) => void = (props) => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') getNewNotification(props);
      else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
          if (permission === 'granted') getNewNotification(props);
        });
      }
    }
  };

  return [send, notification] as [(props: SendProps) => void, Notification];
};

export default useBrowserNotifications;
export { clearBrowserNotifications, closeBrowserNotification };
