import { Profile, ProfileInputModel, ProfileUpdateModel } from 'entities';
import axios from '../axios';

export const getBySlug = (slug: string) =>
  new Promise<Profile>((resolve, reject) => {
    const url = `/profile/${slug}`;
    axios
      .get(url)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getEnabled = () =>
  new Promise<Profile[]>((resolve, reject) => {
    axios
      .get('/profile')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getUsers = (slug: string) =>
  new Promise<string[]>((resolve, reject) => {
    axios
      .get(`/profile/${slug}/users`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const create = (profile: ProfileInputModel) =>
  new Promise<{ slug: string }>((resolve, reject) => {
    axios
      .post('/profile', profile)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const update = (profile: ProfileUpdateModel) =>
  new Promise<void>((resolve, reject) => {
    axios
      .put(`/profile`, profile)
      .then(() => resolve())
      .catch(reject);
  });

export const disable = (
  slug: string,
  usersHandling?: 'delete' | 'transfer',
  usersDestination?: string
) =>
  new Promise<void>((resolve, reject) => {
    axios
      .put(
        `/profile/disable/${slug}?${
          usersHandling ? `usersHandling=${usersHandling}` : ''
        }${
          usersDestination
            ? `${usersHandling ? '&' : ''}usersDestination=${usersDestination}`
            : ''
        }`
      )
      .then(() => resolve())
      .catch(reject);
  });
