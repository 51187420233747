import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type ContextProps = {
  urlLogo: string;
  urlBanner: string;
  urlTerm: string;
  setUrlTerm: Dispatch<SetStateAction<string>>;
  setUrlLogo: Dispatch<SetStateAction<string>>;
  setUrlBanner: Dispatch<SetStateAction<string>>;
};
const ImageContext = createContext({} as ContextProps);

export const ImageProvider: React.FC = ({ children }) => {
  const [urlLogo, setUrlLogo] = useState(
    `${process.env.REACT_APP_API_BASE_URL}/download/by-file-name?FileName=logo&Extension=jpg`
  );
  const [urlBanner, setUrlBanner] = useState(
    `${process.env.REACT_APP_API_BASE_URL}/download/by-file-name?FileName=banner&Extension=jpg`
  );
  const [urlTerm, setUrlTerm] = useState(
    `${process.env.REACT_APP_API_BASE_URL}/download/by-file-name?FileName=termos&Extension=pdf`
  );

  return (
    <ImageContext.Provider
      value={{
        urlLogo,
        setUrlLogo,
        urlBanner,
        setUrlBanner,
        urlTerm,
        setUrlTerm,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export const useImages = () => useContext(ImageContext);
