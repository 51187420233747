import React, { useEffect, createContext, useState, useContext } from 'react';
import { ConfigViewModel } from 'entities';
import usePersistedState from '../../hooks/usePersistedState';
import Loading from '../../components/Loading';
import NetworkError from '../../components/NetworkError';
import Api from '../../api';

type AppContextProps = {
  tenant: string;
  online: boolean;
  loading: boolean;
  setLoading: (props: any) => void;
  getConfig: <T extends object>(key: string) => T | undefined;
};
const AppContext = createContext<AppContextProps>({} as AppContextProps);

const App: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [online, setOnline] = useState<boolean>(true);
  const [configs, setConfigs] = usePersistedState<ConfigViewModel[]>(
    'configs',
    [],
    true
  );

  const tenant = window.location.hostname.split('.')[0];

  const getConfig = <T extends object>(key: string) => {
    try {
      return JSON.parse(configs.find((c) => c.key === key)?.value ?? '') as T;
    } catch {
      return undefined;
    }
  };

  useEffect(() => {
    window.addEventListener('offline', () => setOnline(false));
    window.addEventListener('online', () => setOnline(true));

    return () => {
      window.removeEventListener('offline', () => setOnline(false));
      window.removeEventListener('online', () => setOnline(true));
    };
  }, []);

  useEffect(() => {
    Api.Configs.getPublic().then(setConfigs);
  }, [setConfigs]);

  return (
    <AppContext.Provider
      value={{
        tenant,
        online,
        loading,
        getConfig,
        setLoading,
      }}
    >
      {children}
      <NetworkError shown={!online} />
      <Loading shown={loading} />
    </AppContext.Provider>
  );
};

export const useApp: () => AppContextProps = () => useContext(AppContext);

export default App;
