import { Notification } from 'entities';
import axios from '../axios';

export const getByRefId = (refId: string) =>
  new Promise<Notification[]>((resolve, reject) => {
    const url = `/notification/ref/${refId}`;
    axios
      .get(url)
      .then(({ data }) => resolve(data || []))
      .catch(reject);
  });

export const makeAsReaded = (id: string) =>
  new Promise<void>((resolve, reject) => {
    const url = `${
      process.env.REACT_APP_API_BASE_URL
    }/notification/${id.toUpperCase()}`;
    axios
      .put(url)
      .then(() => resolve())
      .catch(reject);
  });

export const makeAllAsReaded = (ref: string) =>
  new Promise<void>((resolve, reject) => {
    const url = `${
      process.env.REACT_APP_API_BASE_URL
    }/notification/all/${ref.toUpperCase()}`;
    axios
      .put(url)
      .then(() => resolve())
      .catch(reject);
  });

export const deleteById = (id: string) =>
  new Promise<void>((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/notification/${id}`;
    axios
      .delete(url)
      .then(() => resolve())
      .catch(reject);
  });

export const deleteByRefId = (ref: string) =>
  new Promise<void>((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/notification/all/${ref}`;
    axios
      .delete(url)
      .then(() => resolve())
      .catch(reject);
  });
