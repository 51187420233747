import { AuthViewModel } from 'entities';
import axios from '../axios';

export const login = (email: string, password: string) =>
  new Promise<AuthViewModel>((resolve, reject) => {
    axios
      .post('/user/login', {
        email,
        password,
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const logout = () =>
  new Promise<void>((resolve, reject) => {
    axios
      .post('/user/logout')
      .then(() => resolve())
      .catch(reject);
  });

export const recoveryPassword = (email: string) =>
  new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('email', email);
    axios.post('/user/password/recovery', data).then(resolve).catch(reject);
  });

export const changePassword = (id: string, hash: string, password: string) =>
  new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('id', id);
    data.append('hash', hash);
    data.append('password', password);
    axios
      .post('/user/password/recovery/validate', data)
      .then(resolve)
      .catch(reject);
  });
